<template>
  <div v-if="service" class="m-0 p-0 header-card-avatar">
    <b :class=" editable ? 'header-card-avatar-change hover-enabled rounded' : 'header-card-avatar-change rounded'" @click=" editable ? pickAndSaveImg(`integrator-service-${service.id}`) : '' ">
      <feather-icon
        class="position-absolute avatar-edit-icon"
        icon="EditIcon"
        size="18"
      />
      <span>
        Change avatar
      </span>
    </b>
    <b-avatar class="d-inline-block" :src="getImage(`integrator-service-${service.id}`)" url alt="" :size="size" rounded>
      <!-- <b-img
        v-if="service.image_uri"
        id="service-avatar"
        :src="service.image_uri"
        class="header-card-avatar-img"
        width="10000"
      />
      <template v-else>
        <div class="position-absolute bg-light-purple rounded-circle small-card-service-avatar-background">
        </div>
        <span class="text-purple" style="font-size: 36px">
          {{ getAvatarString }}
        </span>
      </template> -->
    </b-avatar>
    <b-form-file
      v-if="editable"
      class="position-absolute header-card-input-file-avatar"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      @click.native.prevent="pickAndSaveImg(`integrator-service-${service.id}`)"
      @change="draggedImage"
      >
    </b-form-file>
    <!-- @change="(event) => onFileChange(event, 'image_uri')" -->
  </div>
  <div v-else>
    <b-avatar :size="size" rounded/>
  </div>
</template>

<script>
import { BAvatar, BTooltip, BFormFile, BImg , BSkeleton} from 'bootstrap-vue'
import {mapGetters} from 'vuex'
import { pickAndSaveImg } from '@/custom/class/FunctionClasses/HelperFunctions.js'

export default {
    components: {
      BAvatar,
      BTooltip,
      BFormFile,
      BImg ,
      BSkeleton,
    },
    props: {
      size: {
        type: [ String, Number ],
        default: null
      },
      editable: {
        type: Boolean,
        default: false
      },
      orgID: {
        type: [ String, Number ],
        required: true
      },
      serviceID: {
        type: [ String, Number ],
        required: true
      },
      serviceGetter: {
        type: [ String, Number ],
        required: true
      }
    },
    data() {
      return {
        version: 0
      }
    },
    computed: {
      ...mapGetters(['getImage']),
      service() {
        let type = typeof(this.$store.getters[this.serviceGetter])

        if (type == 'function') {
          return this.$store.getters[this.serviceGetter](this.orgID, this.serviceID)
        }
        return this.$store.getters[this.serviceGetter]
      },
      getAvatarString() {
        if (!this.service.name || !(typeof this.service.name == "string") ) return

        let arr = this.service.name.split(/[_,-\s]/)
        
        if (arr.length < 2) {
          return arr[0].substring(0, 2).toUpperCase()
        } else {
          return `${arr[0][0]}${arr[1][0]}`.toUpperCase()
        }
      },
    },
    methods: {
      pickAndSaveImg,
      onFileChange(e, target) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        var reader = new FileReader();
        reader.onload = (event) => {
          this.$store.dispatch('serviceModule/patchIntegratorService', 
            { 
              serviceID: this.serviceID,
              orgID: this.orgID,
              field: target,
              new_value: e.target.files[0].name
            }).then(() => {
              this.$store.commit('serviceModule/PATCH_INTEGRATOR_SERVICE_PARAM', {
                param: target,
                orgID: this.orgID,
                serviceID: this.serviceID,
                new_value: event.target.result,
              })
              this.$emit('saved')
            }).catch((error) => {
              console.error(error)
            })
        };
        this.version++

        // this.card[target].append(target, e.target.files[0], e.target.files[0].name);
        // this.card[target].append(target, e.target.files[0], e.target.files[0].name);

        reader.readAsDataURL(files[0]);
      },
      draggedImage(e){
        let file = e.dataTransfer.files[0];   
        let reader = new FileReader();
        reader.onload = () => {
          this.$store.dispatch('saveImage',{identifier: (`integrator-service-${this.service.id}`), base64: reader.result} )
        }
        reader.readAsDataURL(file);
        return 
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";


.general-service-avatar {
  transition: all 0.3s ease;
  .general-service-avatar-edit {
    top: 5px !important;
    right: 30px !important;
    .edit-avatar-icon {
      color: #D0D2D6; 
      transition: all 0.3s ease;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.3s ease;
    .edit-avatar-icon {
      color: $backgroundDark !important;
      transition: all 0.3s ease;
    }
  }
}
</style>

<style lang="scss">
.header-card-avatar {
  .custom-file-label {
    cursor: pointer;
    height: 140px;
  }

  .form-file-text {
    opacity: 0;
    height: 140px;
  }

  &:hover {
    .header-card-avatar-change.hover-enabled {
      cursor: pointer;
      opacity: 1;
      height: 140px;
      z-index: 3;
    }
  }

  .header-card-avatar-change {
    display: block;
    position: absolute;
    height: 140px;
    width: 140px;
    background: #000000d6;
    line-height: 140px;
    text-align: center;
    opacity: 0;

    transition: opacity 0.25s ease;

    .avatar-edit-icon {
      top: 5px;
      right: 5px;
    }
  }
  .header-card-input-file-avatar {
    height: 100%;
    opacity: 0;
    max-width: 140px;
    max-height: 140px;
    left: 0;
    z-index: 6 !important;
    top: 0;
  }
}

</style>